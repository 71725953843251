<template>
    <transition name="fade" mode="out-in">
        <div
            v-if="showMobileMenu"
            class="modal-background"
            @click.self="setShowMobileMenu(false)"
        >
            <div class="mobile-menu" id="mobileMenu">
                <div class="mobile-menu-header">
                    <div class="modal-header-line">
                        <XButton
                            class="x-button"
                            @click="setShowMobileMenu(false)"
                        />
                    </div>
                </div>
                <div class="user-info login-view">
                    <div class="header-profile user-profile">
                        <div class="user-image-bg hide">
                            <div class="user-image"></div>
                        </div>
                        <div
                            class="header-info-word login-user user-name-handler"
                        >
                            Hi,
                            <span class="user-name">
                                {{ $store.state.userInfo.name }}
                            </span>
                        </div>
                        <div
                            v-if="$isLogin()"
                            class="header-info-word login-logout"
                            @click="$logout()"
                        >
                            <div class="logout-icon"></div>
                            登出
                        </div>
                    </div>
                    <div class="btn-container">
                        <div
                            class="btn-wrapper"
                            :class="{ focus: isShowLicenseInfo }"
                            @click="modalHandler('licenseInfo')"
                        >
                            <div class="license-info-btn" />
                        </div>
                        <div
                            class="btn-wrapper"
                            :class="{ focus: isShowQuickLink }"
                            @click="modalHandler('quickLink')"
                        >
                            <div class="quick-link-btn" />
                        </div>
                    </div>
                    <div class="mobile-menu-grep"></div>
                </div>
                <div class="index-link">
                    <div
                        class="header-info-word"
                        :class="{ bold: $route.name === 'Home' }"
                    >
                        <router-link
                            to="/home"
                            @click.self="setShowMobileMenu(false)"
                            >首頁</router-link
                        >
                    </div>
                    <Section />
                </div>
                <div
                    v-if="!$isLogin()"
                    class="header-info-word login-logout loginBtnStyle"
                    @click="$login()"
                >
                    <div class="logout-icon"></div>
                    登入
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import XButton from '@/components/XButton.vue'
import Section from '@/containers/Section.vue'

export default {
    name: 'MobileMenu',
    components: {
        Section,
        XButton
    },
    methods: {
        setShowMobileMenu: function (isShow) {
            this.$store.commit('set', {
                showMobileMenu: isShow
            })
        },
        modalHandler: function (modal = '') {
            if (modal === 'licenseInfo') {
                this.setShowMobileMenu(false)
                this.$store.commit('set', {
                    isShowLicenseInfo: true
                })
            }
            if (modal === 'quickLink') {
                this.setShowMobileMenu(false)
                this.$store.commit('set', {
                    isShowQuickLink: true
                })
            }
        }
    },
    computed: {
        showMobileMenu: function () {
            return this.$store.state.showMobileMenu
        },
        isShowLicenseInfo: function () {
            return this.$store.state.isShowLicenseInfo
        },
        isShowQuickLink: function () {
            return this.$store.state.isShowQuickLink
        }
    },
    watch: {
        showMobileMenu: function () {
            const body = document.querySelector('body')
            if (this.showMobileMenu) {
                this.$addAllClass(body, 'not-scroll')
            } else {
                this.$removeAllClass(body, 'not-scroll')
            }
        }
    },
    created() {
        if (this.showMobileMenu) {
            this.setShowMobileMenu(false)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/icon.scss';
.modal-header-line {
    width: 100%;
    height: 18px;
    display: flex;
    justify-content: flex-end;
}

.x-button {
    margin: -8px 4px;
}

.mobile-menu a {
    color: $sixth-black;
    font-family: $secondary-cn;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    font-weight: normal;
}

.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    box-sizing: border-box;
    z-index: 8;
}

.mobile-menu {
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    display: block;
    background-color: $primary-white;
    box-sizing: border-box;
    padding-left: Max(20px, env(safe-area-inset-left, 20px));
    padding-right: Max(20px, env(safe-area-inset-right, 20px));
    padding-top: Max(32px, env(safe-area-inset-top, 32px));
    padding-bottom: Max(32px, env(safe-area-inset-bottom, 32px));

    @media screen and (min-width: 577px) {
        width: 360px;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    .section {
        display: block;
        width: 100%;
        height: auto;
    }

    .product-category {
        @media (max-width: 991px) {
            display: block;
        }
    }

    .mobile-menu-grep {
        margin-left: -20px;
        height: 1px;
        width: 100vw;
        background-color: rgba(170, 173, 179, 0.3);
        margin-top: 10px;
    }

    .header-info-word {
        color: $sixth-black;
        font-family: $secondary-cn;
        font-size: 16px;
        line-height: 22px;
        cursor: pointer;
        margin-right: 10px;
        min-width: 60px;

        &.login-logout {
            display: flex;
            align-items: center;
            color: $fourth-red;
            font-family: $secondary-cn;
            font-size: 16px;
            line-height: 22px;
            cursor: pointer;

            &.loginBtnStyle {
                color: $sixth-black;
            }

            .logout-icon {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
        }
    }

    .index-link .header-info-word {
        display: flex;
        min-height: 50px;
        align-items: center;
        margin-top: 10px;
        padding: 0px 15px;
        font-size: 18px;
        &.bold {
            a {
                font-weight: bold;
            }
        }
    }

    .header-link .header-info-word {
        padding: 10px 24px;

        &.login-user {
            color: $secondary-grey;
            font-family: $secondary-cn;
            font-size: 14px;
            line-height: 20px;
            cursor: default;
            padding: 10px 8px;
            line-height: 20px;
        }
    }

    .user-profile {
        &.header-profile {
            display: flex;
            align-items: flex-start;
        }

        padding: 0px 12px;
        margin: 10px 0px;
    }

    .user-image-bg {
        background-color: $third-blue;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .user-image {
        color: $primary-white;
        font-family: $secondary-cn;
        font-size: 21px;
        line-height: 29px;
    }
}

.section {
    height: calc(100vh - #{$header-height});
    width: $secondary-section-width;
    padding-right: 20px;
    box-sizing: border-box;
    @media screen and (min-width: 1281px) {
        width: $secondary-section-width;
    }
    @media screen and (min-width: 992px) and (max-width: 1280px) {
        width: $secondary-section-width;
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
        width: $third-section-width;
        display: none;
    }
    @media screen and (min-width: 576px) and (max-width: 767px) {
        width: $third-section-width;
        display: none;
    }
    @media screen and (min-width: 0px) and (max-width: 575px) {
        width: $third-section-width;
        display: none;
    }
}

.section-list {
    display: none;
    padding-left: 20px;
}

.section-item {
    display: flex;
    align-items: center;
    height: 50px;
    cursor: pointer;
    .section-item-grep {
        height: 20px;
        width: 4px;
        background-color: $secondary-red;
        opacity: 0;
    }
    .section-item-word {
        max-width: 200px;
        color: $sixth-black;
        font-size: 16px;
        line-height: 22px;
        margin-left: 15px;
    }
    .toggle-menu-icon {
        height: 12px;
        width: 7px;
        margin-left: 10px;
    }
    &.active {
        .toggle-menu-icon {
            transform: rotate(90deg);
        }
        & + .section-list {
            display: block;
        }
    }
    &.selected {
        .section-item-grep {
            opacity: 1;
        }
        :not(.not-visible) + .section-item-word {
            opacity: 1;
            font-weight: 600;
        }
    }
}

.section-icon {
    height: 22px;
    width: 22px;
    margin: 0 20px 0 0;
    align-self: center;
    @media screen and (min-width: 1281px) {
        display: none;
    }
    @media screen and (min-width: 992px) and (max-width: 1280px) {
        display: none;
    }
}
.btn-container {
    display: flex;
    padding: 5px 12px;
    @media screen and (min-width: 577px) {
        display: none;
    }
    .btn-wrapper {
        display: flex;
        align-items: center;
        margin-right: 20px;
        width: 40px;
        height: 40px;
        cursor: pointer;
        > div {
            margin-left: auto;
            margin-right: auto;
        }
        .license-info-btn {
            @include license_info($sixth-black);
            background-size: 24px 20px;
            width: 24px;
            height: 20px;
        }
        .quick-link-btn {
            @include candy_box($sixth-black);
            background-size: 20px 20px;
            width: 20px;
            height: 20px;
        }
        &.focus {
            border-radius: 50%;
            background: $dashboard-gradient;
            .license-info-btn {
                @include license_info($primary-white);
            }

            .quick-link-btn {
                @include candy_box($primary-white);
            }
        }
    }
}

.user-info {
    display: none;
    @media screen and (max-width: 576px) {
        display: block;
    }
}
</style>
